import React from 'react'

const Dashboard = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h2>Welcome To Zens Health Care Admin Panel !!!!</h2>
      </div>
    </>
  )
}

export default Dashboard